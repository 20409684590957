import ApiService from 'api-client/ApiService'

class Placement extends ApiService {
  public getClassName() {
    return 'placement'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('placement', 'public')
  }

  v1 = () => `/v1.10`

  async getApis() {
    const response = await this.client.basicGet<any>({
      url: '/',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackPlacementApis',
      },
    })
    return response
  }

  async getResources() {
    const response = await this.client.basicGet<any>({
      url: `/resource_providers`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getResources',
      },
    })
    return response?.resource_providers
  }

  async getResourceInventories({ id }) {
    const response = await this.client.basicGet<any>({
      url: `/resource_providers/${id}/inventories`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getResourceProvidersInventories',
      },
    })
    return response
  }

  async deleteResourceInventory(id) {
    return this.client.basicDelete<any>({
      url: `/resource_providers/${id}/inventories`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteResourceProviderInventory',
      },
    })
  }

  async updateResourceInventory({ id, body }) {
    const url = `/resource_providers/${id}/inventories`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackResourceInventory',
      },
    })
    return response
  }
}

export default Placement
