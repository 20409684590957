import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listVolumeTypes } from './actions'
import { useSelector } from 'react-redux'
import { volumeTypesSelector } from './selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function VolumeTypesPicklist({
  disabled = false,
  name = 'volumeTypes',
  label = 'Volume Type',
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listVolumeTypes)
  const volumeTypes = useSelector(volumeTypesSelector)

  const options = useMemo(() => {
    const volumeTypeOptions = volumeTypes.map((volumeType) => ({
      label: volumeType.name || volumeType.id,
      value: volumeType.name || volumeType.id,
    }))
    return includeNoneOption
      ? [{ label: 'None', value: '' }, ...volumeTypeOptions]
      : volumeTypeOptions
  }, [volumeTypes, includeNoneOption])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
