import { makeStyles } from '@material-ui/styles'
import { AppPlugins } from 'app/constants'
import { pathStrOr } from 'app/utils/fp'
import clsx from 'clsx'
import { clientActions, ClientState, clientStoreKey } from 'core/client/clientReducers'
import Text from 'core/elements/Text'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useToggler from 'core/hooks/useToggler'
import { createUrlWithQueryString } from 'core/plugins/route'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import FrameContext from 'core/providers/frame-provider'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import GlobalClusterDropdown from 'k8s/components/GlobalClusterDropdown'
import { partition, prop } from 'ramda'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/tracking'
import NavItem from './NavItem'
import NavPane from './NavPane'
import NavSection from './NavSection'
import PluginMenu from './PluginMenu'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'
import IconButton from 'core/elements/button/IconButton'

interface Props {
  setPluginId: (id: string) => void
}

const sidebarPaneRef = React.createRef<HTMLDivElement>()
const Sidebar: FC<Props> = ({ setPluginId }) => {
  const dispatch = useDispatch()
  const [menuOpen, toggleMenuOpen] = useToggler(false)
  const { features = {} } = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { frame: { sidebarState = 'expanded', sidebarPane = 'default' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const drawerOpen = sidebarState === 'expanded'
  const version = pathStrOr('4', 'releaseVersion', features)
  const { sections, currentSection, currentPath, currentPluginId } = usePluginRouter()
  const classes = useStyles({ drawerOpen, menuOpen })
  const [currentSectionBottomLinks, currentSectionLinks] = useMemo(
    () => partition((link) => link.isBottomLink, currentSection.links),
    [currentSection],
  )
  const { prefs: k8sGlobalPrefs } = useScopedPreferences('k8sPluginGlobalParams')
  useListAction(listKaapiClusters)
  const clusterList = useSelector(kaapiClustersSelector)
  const toggleDrawer = useCallback(() => {
    dispatch(clientActions.setSidebarState(sidebarState === 'expanded' ? 'collapsed' : 'expanded'))
  }, [sidebarState])

  const { setFrameContainerRef } = React.useContext(FrameContext)
  useEffect(() => {
    setFrameContainerRef({
      sidebarPaneContainer: sidebarPaneRef.current,
    })
  }, [])

  const handlePluginChange = useCallback(
    (newPluginId) => {
      setPluginId(newPluginId)
      trackEvent('Changed Application Plugin', {
        application: newPluginId,
      })
      toggleMenuOpen()
    },
    [setPluginId],
  )
  const navMenuOffset = useMemo(
    () => ({
      vertical: -48,
      horizontal: 8,
    }),
    [drawerOpen],
  )

  const bottomContent = useMemo(
    () =>
      drawerOpen
        ? [
            <li key={version}>
              <Text variant="body2" component="h6" className={classes.version}>
                Version: {version}
              </Text>
            </li>,
          ]
        : currentSectionBottomLinks.map((bLink, idx) => (
            <NavItem
              {...bLink}
              key={idx}
              isActive={matchesCurrentPath(currentPath, bLink.link)}
              drawerOpen={drawerOpen}
              // tooltip
              tooltipProps={{ className: classes.navText }}
            />
          )),
    [version, drawerOpen, currentPath, currentSectionBottomLinks],
  )

  const navItems = useMemo(() => {
    if (currentPluginId !== AppPlugins.Kubernetes) return currentSectionLinks

    return currentSectionLinks.map((section, idx) => {
      const link = {
        ...(section.link || {}),
        path: section.link?.path?.includes(':')
          ? createUrlWithQueryString(section.link?.path || '', {
              cluster: k8sGlobalPrefs?.cluster as string,
            })
          : section.link?.path,
      }

      return {
        ...section,
        link,
        nestedLinks: section.nestedLinks?.map((nestedLink) => ({
          ...nestedLink,
          link: {
            ...(nestedLink.link || {}),
            path: nestedLink.link?.path?.includes(':')
              ? createUrlWithQueryString(nestedLink.link?.path || '', {
                  cluster: k8sGlobalPrefs?.cluster as string,
                })
              : nestedLink.link?.path,
          },
        })),
      }
    })
  }, [k8sGlobalPrefs?.cluster, currentPluginId, currentSectionLinks])

  const renderNavItems = useCallback(
    (showAtTop = false) => {
      return navItems.map(
        (navItem, idx) =>
          !!navItem?.showAtTop === !!showAtTop && (
            <NavSection
              {...navItem}
              key={`${navItem?.name}-${idx}`}
              isActive={matchesCurrentPath(currentPath, navItem?.link)}
              drawerOpen={drawerOpen}
              menuOffset={navMenuOffset}
              disabled={!clusterList?.length && currentPluginId === AppPlugins.Kubernetes}
              info={
                !clusterList?.length && currentPluginId === AppPlugins.Kubernetes
                  ? 'No Clusters Available'
                  : ''
              }
            />
          ),
      )
    },
    [navItems, currentPath, drawerOpen, navMenuOffset, clusterList],
  )

  return (
    <>
      <aside
        className={clsx(classes.customNav, {
          [classes.customNavWidth]: sidebarPane === 'custom',
          [classes.openPane]: sidebarPane === 'custom',
          [classes.hiddenPane]: sidebarPane !== 'custom',
        })}
      >
        <div className="sidebarPanePortal" ref={sidebarPaneRef} />
      </aside>
      <aside
        className={clsx(classes.customNav, classes.nav, {
          [classes.openPane]: sidebarPane === 'default',
          [classes.hiddenPane]: sidebarPane !== 'default',
        })}
      >
        <PluginMenu
          open={menuOpen}
          toggleMenu={toggleMenuOpen}
          collapse={!drawerOpen}
          options={sections}
          onPluginChange={handlePluginChange}
          currentPluginId={currentPluginId}
        />
        {sidebarPane !== 'custom' && (
          <IconButton
            className={classes.toggleCaret}
            onClick={toggleDrawer}
            icon={drawerOpen ? 'chevron-left' : 'chevron-right'}
            size="md"
            solid
          />
        )}
        <NavPane className={clsx(classes.primaryPane, 'thin-scrollbar')}>
          {currentPluginId === AppPlugins.Kubernetes && (
            <>
              <div className={classes.allClustersLabel}>
                <Text variant="caption2" className={classes.label}>
                  ALL CLUSTERS
                </Text>
              </div>
              {renderNavItems(true)}
              <br />
              <li key="clusterDropdown">
                <div className={classes.perClusterLabel}>
                  <Text variant="caption2" className={classes.label}>
                    PER CLUSTER
                  </Text>
                </div>
                <GlobalClusterDropdown className={classes.cloudspaceDropdown} />
              </li>
            </>
          )}
          {renderNavItems()}
        </NavPane>
      </aside>
    </>
  )
}

interface StyleProps {
  drawerOpen: boolean
  menuOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  hiddenPane: {
    transform: ({ drawerOpen }) => `translate(-${drawerOpen ? '289' : '73'}px, 0px)`,
  },
  openPane: {
    transform: ({ drawerOpen }) => `translate(0px, 0px)`,
    backgroundColor: theme.components.sidebar.background,
  },
  customNavWidth: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '100%',
      bottom: 0,
      width: 128,
      backgroundColor: theme.components.sidebar.background,
    },

    '& ~section.content-main': {
      zIndex: 1000,
      padding: '80px 32px',
    },
  },
  nav: {
    position: 'relative',
    width: ({ drawerOpen }) => (drawerOpen ? 288 : 72),
    zIndex: 100,
    height: '100vh',
    borderRight: `1px solid ${theme.components.sidebar.border}`,
    backgroundColor: theme.components.sidebar.background,
    display: 'grid',
    justifyContent: 'stretch',
    transition: 'width .2s ease',
    gridTemplateRows: 'max-content 1fr',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `"nav-header" "nav-primary-pane"`,
  },
  toggleCaret: {
    backgroundColor: theme.palette.grey['000'],
    // this box shadow a bit intense with the lighter theme
    // boxShadow: '0 4px 24px 0 rgba(37, 37, 63, 0.25)',
    position: 'absolute',
    right: 0,
    top: 64 - 24 / 2,
    width: 24,
    height: 24,
    borderRadius: 24,
    border: 'none',
    transform: 'translate(50%, 0)',
    color: theme.palette.grey[900],
    outline: '0 !important',
    zIndex: 10,
    alignItems: 'center',
    justifyItems: 'center',
    display: ({ menuOpen }) => (menuOpen ? 'none' : 'grid'),
    '&:hover': {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[100],
    },
    '& i': {
      fontSize: 12,
      width: 'auto',
    },
  },
  primaryPane: {
    gridArea: 'nav-primary-pane',
    borderRight: `1px solid ${theme.components.sidebar.border}`,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    overflowX: 'auto',
  },
  secondaryPane: {
    gridArea: 'nav-secondary-pane',
    paddingTop: 16,
    gap: 8,

    '& > h6': {
      marginLeft: 16,
    },
  },
  navText: {
    display: 'grid',
    width: '100%',
    gridAutoFlow: 'column',
    gridTemplateColumns: '72px 1fr',
  },
  textOnlyNavItem: {
    paddingLeft: 32,
  },
  version: {
    margin: theme.spacing(1, 0),
    color: theme.components.sidebar.text,
    textAlign: 'center',
  },
  cloudspaceDropdown: {
    marginLeft: theme.spacing(3),
    width: `${288 - 24 - 24}px`, // sidebar width - left and right padding
    padding: theme.spacing(2, 0, 3, 0),
  },
  allClustersLabel: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    margin: theme.spacing(3, 0, 0.5, 3),
  },
  perClusterLabel: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    padding: theme.spacing(2, 0, 0, 3),
    borderTop: `1px solid ${theme.components.sidebar.border}`,
  },
  label: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    letterSpacing: 2,
  },
}))

export default Sidebar
