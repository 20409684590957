import React, { useCallback } from 'react'
import { styled } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { retypeVolume } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import VolumeTypesPicklist from '../volume-types/VolumeTypesPicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'

const Container = styled('div')({
  gap: 16,
  display: 'grid',
})

export default function RetypeVolumeDialog({ rows: [volume], onClose }) {
  const { params, getParamsUpdater } = useParams({
    volumeType: volume?.volume_type,
  })
  const { update, updating, error, reset } = useUpdateAction(retypeVolume)

  const handleSubmit = useCallback(async () => {
    const body = {
      'os-retype': {
        new_type: params.volumeType,
      },
    }
    const { success } = await update({
      id: volume?.id,
      body,
    })
    if (success) handleClose()
  }, [volume, params])

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Retype Volume"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Retype Volume`}
      panel="dialog"
      open
    >
      <Container>
        <Text variant="body2">Select a new volume type to retype this volume to.</Text>
        <PicklistField
          DropdownComponent={VolumeTypesPicklist}
          id="volumeType"
          onChange={getParamsUpdater('volumeType')}
          value={params.volumeType}
          label="Volume Type"
        />
      </Container>
    </ModalForm>
  )
}
