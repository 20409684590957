import React, { useCallback, useEffect, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteImage, getHostGlanceEndpoint } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'

export default function DeleteImage({ rows: [items], onClose }) {
  const [showWarning, setShowWarning] = useState(false)
  useEffect(() => {
    const asyncCall = async () => {
      const endpoint = await getHostGlanceEndpoint()
      if (!endpoint) {
        setShowWarning(true)
      }
    }
    asyncCall()
  }, [])

  const { update: deleteFn, updating: deletingItems, error } = useUpdateAction(deleteImage)

  const handleDelete = useCallback(async () => {
    const { success } = await deleteFn(items)
    if (success) {
      setShowWarning(false)
      onClose(true)
    }
  }, [items])

  return (
    <Modal
      panel="dialog"
      title="Delete Image"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingItems}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingItems}>
            Yes, delete
          </Button>
        </>
      }
    >
      <>
        {showWarning ? (
          <Text variant="body2">
            You are about to delete an image that belongs to a host without an Image Library role.
            This action cannot be undone. Do you want to proceed?
          </Text>
        ) : (
          <Text variant="body2">This will permanently delete {items?.name}</Text>
        )}
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
