import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { glance } = ApiClient.getInstance()

export const imageActions = ActionsSet.make<DataKeys.Images>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Images,
  cacheKey: DataKeys.Images,
})

export const listImages = imageActions.add(
  new ListAction<DataKeys.Images>(async () => {
    return glance.getImages()
  }),
)

export const createImage = imageActions.add(
  new CreateAction<DataKeys.Images, { body }>(async ({ body }) => {
    const created = await glance.createImage(body)
    const imageId = created.id
    const newImage = await glance.getImage(imageId)
    return newImage
  }),
)

export const updateImage = imageActions.add(
  new UpdateAction<
    DataKeys.Images,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedImage = await glance.patchImage({ id, body })
    return updatedImage
  }),
)

export const deleteImage = imageActions.add(
  new DeleteAction<DataKeys.Images, { id: string }>(async ({ id }) => {
    await glance.deleteImage(id)
  }),
)

export const uploadImage = imageActions.add(
  new CustomAction<DataKeys.Images, { id: string; body?: any }>(
    'uploadImage',
    async ({ id, body }) => {
      await glance.uploadImage({ id, body })
      const image = await glance.getImage(id)
      return image
    },
    (result, { id }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.Images,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const importImage = imageActions.add(
  new CustomAction<DataKeys.Images, { id: string; body?: any }>(
    'importImage',
    async ({ id, body }) => {
      await glance.importImage({ id, body })
      const image = await glance.getImage(id)
      return image
    },
    (result, { id }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.Images,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const testHostGlance = async () => {
  return glance.testHostGlanceAccess()
}

export const getHostGlanceEndpoint = async () => {
  return glance.getHostGlanceEndpoint()
}
