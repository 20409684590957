import ApiService from 'api-client/ApiService'

class Glance extends ApiService {
  public getClassName() {
    return 'glance'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('glance', 'public')
    // return this.client.keystone.getServiceEndpoint('glance', 'admin') // use this when using sbux-scaletest
  }

  // v2 = () => `/v2`

  imagesUrl = () => `/api/glance/v2/images`
  hostUrl = () => {
    return this.client.keystone.getServiceEndpoint('glance-cluster', 'admin')
  }

  getImages = async () => {
    const response = await this.client.basicGet<any>({
      url: `/v2/images`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getImages',
      },
    })
    return response.images
  }

  async getImage(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2/images/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getImage',
      },
    })
    return response
  }

  async createImage(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2/images`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createImage',
      },
    })
    return response
  }

  async uploadImage({ id, body }) {
    const hostUrl = await this.hostUrl()
    const response = await this.client.basicPut<any>({
      endpoint: hostUrl,
      url: `/v2/images/${id}/file`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'uploadImage',
        config: {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          timeout: 36000000,
        },
      },
    })
    return response
  }

  async testHostGlanceAccess() {
    const hostUrl = await this.hostUrl()
    const response = await this.client.basicGet<any>({
      endpoint: hostUrl,
      url: '',
      options: {
        clsName: this.getClassName(),
        mthdName: 'testHostGlance',
      },
    })
    return response
  }

  async getHostGlanceEndpoint() {
    const hostUrl = await this.hostUrl()
    return hostUrl
  }

  async importImage({ id, body }) {
    const hostUrl = await this.hostUrl()
    const response = await this.client.basicPost<any>({
      endpoint: hostUrl,
      url: `/v2/images/${id}/import`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'importImage',
      },
    })
    return response
  }

  patchImage = async ({ id, body }) => {
    const response = await this.client.basicPatch<any>({
      url: `/v2/images/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchImage',
        config: {
          headers: {
            'Content-Type': 'application/openstack-images-v2.1-json-patch',
          },
        },
      },
    })
    return response
  }

  deleteImage = async (id) => {
    const hostUrl = await this.hostUrl()
    return this.client.basicDelete<any>({
      endpoint: hostUrl ? hostUrl : undefined,
      url: `/v2/images/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteImage',
      },
    })
  }
}

export default Glance
