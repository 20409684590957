import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  createHostAggregate,
  listHypervisors,
  addHostToAggregate,
  updateHostAggregateMetadata,
  getResources,
  listResourceInventory,
  updateResourceInventory,
} from '../actions'
import { hypervisorsSelector } from '../selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AvailabilityZonesPicklist from '../availability-zones/AvailabilityZonesPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listAvailabilityZones } from '../availability-zones/actions'
import { availabilityZonesSelector } from '../availability-zones/selectors'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { customValidator } from 'core/utils/fieldValidators'
import { useSelector } from 'react-redux'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

interface Props {
  addRoute: Route
}

const hypervisorColumns = [
  {
    id: 'hypervisor_hostname',
    label: 'Host',
  },
  {
    id: 'host_ip',
    label: 'IP Address',
  },
]

export default function AddHostAggregateModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    availabilityZone: '',
    newAvailabilityZone: false,
    availabilityZoneName: '',
    metadata: [],
    hosts: [],
    makeAvailabilityZone: false,
    customizeAllocationRatio: false,
    VCPU: '',
    MEMORY_MB: '',
    DISK_GB: '',
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const [submitting, setSubmitting] = useState(false)
  const [resources, setResources] = useState([])

  const { update, updating, error, reset } = useUpdateAction(createHostAggregate)
  const {
    update: addHost,
    updating: addingHost,
    error: errorAddingHost,
    reset: resetAddHost,
  } = useUpdateAction(addHostToAggregate)
  const {
    update: updateMetadata,
    updating: updatingMetadata,
    error: errorUpdatingMetadata,
    reset: resetUpdateMetadata,
  } = useUpdateAction(updateHostAggregateMetadata)

  // const { reload: reloadAvailabilityZones } = useListAction(listAvailabilityZones)
  // const availabilityZones = useSelector(availabilityZonesSelector)

  const { loading: loadingHypervisors } = useListAction(listHypervisors, {
    params: {},
  })
  const hypervisorsList = useSelectorWithParams(hypervisorsSelector, {})

  useEffect(() => {
    const getAllRsources = async () => {
      const resources = await getResources()
      setResources(resources)
    }

    getAllRsources()
  }, [])

  // const azNameValidator = useMemo(() => {
  //   return customValidator((name) => {
  //     const nameExists = availabilityZones.some((az) => {
  //       return az.zoneName === name
  //     })
  //     return !nameExists
  //   }, 'Availability Zone name is already taken')
  // }, [availabilityZones])

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const body = {
      aggregate: {
        name: params.name,
        // availability_zone: params.makeAvailabilityZone ? params.availabilityZoneName : undefined,
      },
    }
    const metadata = params.metadata.reduce((accum, pair) => {
      // Don't let user set the availability zone
      if (pair.key === 'availability_zone') {
        return accum
      }
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})

    const metadataBody = {
      set_metadata: {
        metadata,
      },
    }

    const { success, response } = await update({ body })
    const aggregateId = response?.id

    if (success) {
      updateMetadata({ id: aggregateId, body: metadataBody })
      const promises = params.hosts.map((host) => {
        return addHost({
          id: aggregateId,
          body: {
            add_host: {
              host: host?.service?.host,
            },
          },
        })
      })

      const filterResourcesUUIDByHostName = resources.filter(resource => {
        const filteredHostByName = params.hosts.map(host => host?.hypervisor_hostname)
        return filteredHostByName.includes(resource.name)
      }).map(resource => resource.uuid)

      const allocationRatioPromises = filterResourcesUUIDByHostName.map(async (uuid) => {
        const { resource_provider_generation, inventories } =
          await listResourceInventory({ id: uuid })

        const filteredInvetories = Object.keys(inventories).reduce((accum, current) => {
          return {
            ...accum,
            [current]:
            {
              ...inventories[current],
              allocation_ratio: !!params[current] ?
                Number(params[current]) :
                inventories[current].allocation_ratio
            }
          }
        }, {})

        return updateResourceInventory({
          id: uuid,
          body: {
            inventories: filteredInvetories,
            resource_provider_generation: resource_provider_generation
          }
        })
      })

      const data = await Promise.allSettled([...promises, ...allocationRatioPromises])
      if (data)
        handleClose()
    }
    setSubmitting(false)
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetAddHost()
    resetUpdateMetadata()
    // reloadAvailabilityZones(true, true)
    history.push(routes.openstack.hostAggregates.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Host Aggregate`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      error={error}
      submitTitle={`Create Host Aggregate`}
    >
      <>
        <FormFieldSection title="Host Aggregate Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <CheckboxField
            id="customizeAllocationRatio"
            label="Customize Host Allocation Ratios"
            value={params.customizeAllocationRatio}
            onChange={getParamsUpdater('customizeAllocationRatio')}
          />
          {
            params.customizeAllocationRatio && (
              <>
                <TextField
                  id="cpu"
                  label="CPU Allocation Ratio"
                  onChange={getParamsUpdater('VCPU')}
                  value={params.VCPU}
                />
                <TextField
                  id="memory"
                  label="Memory Allocation Ratio"
                  onChange={getParamsUpdater('MEMORY_MB')}
                  value={params.MEMORY_MB}
                />
                <TextField
                  id="disk"
                  label="Disk Allocation Ratio"
                  onChange={getParamsUpdater('DISK_GB')}
                  value={params.DISK_GB}
                />
              </>
            )
          }
          {/* <ToggleSwitchField
            id="makeAvailabilityZone"
            label="Mark Aggregate as Availability Zone"
            value={params.makeAvailabilityZone}
            onChange={getParamsUpdater('makeAvailabilityZone')}
          /> */}
          {/* <CheckboxField
            id="newAvailabilityZone"
            label="Create New Availability Zone"
            value={params.newAvailabilityZone}
            onChange={getParamsUpdater('newAvailabilityZone')}
          /> */}
          {/* {!!params.makeAvailabilityZone && (
            <TextField
              id="availabilityZoneName"
              label="New Availability Zone Name"
              onChange={getParamsUpdater('availabilityZoneName')}
              value={params.availabilityZoneName}
              validations={[azNameValidator]}
              required
            />
          )} */}
          {/* {!params.newAvailabilityZone && (
            <PicklistField
              DropdownComponent={AvailabilityZonesPicklist}
              name="availabilityZone"
              id="availabilityZone"
              label="Availability Zone"
              compact={false}
              onChange={getParamsUpdater('availabilityZone')}
              includeNoneOption
            />
          )} */}
          <KeyValuesField
            id="metadata"
            label="Metadata"
            value={params.metadata}
            onChange={getParamsUpdater('metadata')}
            addLabel="Add Metadata"
            allowMultipleValues
          />
        </FormFieldSection>
        <FormFieldSection title="Select Hosts to Add">
          <ListTableField
            id="hosts"
            data={hypervisorsList}
            loading={loadingHypervisors}
            columns={hypervisorColumns}
            onChange={getParamsUpdater('hosts')}
            value={params.hosts}
            uniqueIdentifier="id"
            searchTargets={['hypervisor_hostname']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
