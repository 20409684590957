import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo, useEffect, useState } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { getVmEvents } from '../actions'
import Grid from 'core/elements/grid'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import moment from 'moment'
import TaskDetailsDialog from './TaskDetailsDialog'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import ErrorModal from '../ErrorModal'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

interface StyleProps {
  error?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  tasks: {
    marginTop: '16px',
  },
  taskMessage: {
    color: ({ error }) => (error ? 'red' : 'green'),
  },
  taskError: {
    display: 'block',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
}))

const ViewDetailsButton = ({ vmId, requestId, setSelectedRequestId, setShowTaskDetailsDialog }) => {
  return (
    <Button
      onClick={() => {
        setSelectedRequestId(requestId)
        setShowTaskDetailsDialog(true)
      }}
    >
      View Details
    </Button>
  )
}

export default function TasksPage({ vm, loading }) {
  const classes = useStyles({ error: false })

  const [loadingTasks, setLoadingTasks] = useState(true)
  const [tasks, setTasks] = useState([])
  const [selectedRequestId, setSelectedRequestId] = useState(null)
  const [showTaskDetailsDialog, setShowTaskDetailsDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const isAdminUser = isAdmin()

  useListAction(listUsers)
  const users = useSelector(usersSelector)
  useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const columns: GridViewColumn<any>[] = useMemo(() => {
    const sharedColumns = [
      {
        key: 'action',
        label: 'Task',
        // width: 'medium',
      },
      {
        key: 'message',
        label: 'Result',
        CellComponent: ({ item }) => {
          const message = item?.message
          const classes = useStyles({ error: !!message })
          return message === 'Error' ? (
            <Text variant="body2" className={classes.taskMessage}>
              {!!message ? 'Error' : 'Success'}
            </Text>
          ) : (
            <div onClick={() => setErrorMessage(message)}>
              <Text variant="body2" className={classes.taskMessage}>
                {!!message ? 'Error' : 'Success'}
              </Text>
              {!!message && (
                <Text variant="caption2" className={classes.taskError}>
                  {message}
                </Text>
              )}
            </div>
          )
        },
      },
      {
        key: 'user_id',
        label: 'User',
        render: (id) => {
          const user = users.find((u) => u.id === id)
          return user ? user.email || user.name : id
        },
      },
      {
        key: 'start_time',
        label: 'Start Time',
        render: (time) => moment(time).format('LL LTS'),
      },
      {
        key: 'project_id',
        label: 'Tenant',
        render: (id) => {
          const tenant = tenants.find((t) => t.id === id)
          return tenant ? tenant.name : id
        },
        display: false,
      },
    ]
    return isAdminUser
      ? [
          ...sharedColumns,
          {
            key: 'request_id',
            label: 'View Details',
            render: (requestId) => (
              <ViewDetailsButton
                vmId={vm?.id}
                requestId={requestId}
                setSelectedRequestId={setSelectedRequestId}
                setShowTaskDetailsDialog={setShowTaskDetailsDialog}
              />
            ),
          },
        ]
      : sharedColumns
  }, [users, tenants, vm, isAdminUser])

  useEffect(() => {
    const getEvents = async () => {
      const events = await getVmEvents({ id: vm?.id })
      setTasks(events)
      setLoadingTasks(false)
    }

    getEvents()
  }, [])

  return (
    <article className={classes.tasks}>
      {showTaskDetailsDialog && (
        <TaskDetailsDialog
          vm={vm}
          requestId={selectedRequestId}
          onClose={() => setShowTaskDetailsDialog(false)}
        />
      )}
      <Grid
        label="Tasks"
        uniqueIdentifier="request_id"
        data={tasks}
        columns={columns}
        loading={loading || loadingTasks}
        compact
      />
      {errorMessage && <ErrorModal error={errorMessage} onClose={() => setErrorMessage(null)} />}
    </article>
  )
}
