import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useDropzone } from 'react-dropzone'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import withFormContext from 'core/components/validatedForm/withFormContext'
import SimpleLink from 'core/components/SimpleLink'

interface Props {
  onChange: (value: any) => void
  onChangeName?: (value: string) => void
  fileTypes: string[]
  value?: any
  id?: string
  validations?: any[]
  hasError?: boolean
  errorMessage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    padding: '16px 16px 32px',
    background: theme.components.card.background,
    border: `1px dashed ${theme.components.dropdown.border}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
  },
  uploadIcon: {
    color: theme.components.card.passiveText,
  },
  iconContainer: {
    background: theme.components.card.activeBackground,
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 25,
  },
  dropzoneText: {
    marginTop: 8,
  },
  imagePreview: {
    maxHeight: 44,
    maxWidth: 48,
  },
  trash: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: theme.components.card.passiveText,
    cursor: 'pointer',
  },
  errorText: {
    color: theme.components.graph.error,
  },
  loadedFile: {
    marginTop: 8,
  },
  progressBar: {
    marginTop: 16,
  },
}))

const Warning = ({ children }) => {
  const classes = useStyles({})
  return (
    <Text variant="body1" className={classes.errorText}>
      {children}
    </Text>
  )
}

const FileDrop: React.ComponentType<Props> = forwardRef<HTMLElement, Props>(
  (props, ref: React.Ref<HTMLDivElement>) => {
    const [fileName, setFileName] = useState('')
    const { onChange, onChangeName, fileTypes = [], value, hasError, errorMessage, id } = props

    const classes = useStyles({})

    useEffect(() => {
      if (!fileName && value)
        setFileName(value?.name)
    }, [fileName])

    const onDrop = useCallback(
      (acceptedFiles) => {
        const file = acceptedFiles[0]
        setFileName(file?.name)
        onChange(file)
        onChangeName(file?.name)
      },
      [onChangeName],
    )

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      noClick: true,
      accept: fileTypes.join(', '),
      maxFiles: 1,
    })

    return (
      <div id={id}>
        <div className={classes.dropzone} {...getRootProps()}>
          <input {...getInputProps()} />
          <div>
            <div className={classes.iconContainer}>
              <img src="/ui/images/cloud-upload@3x.png" className={classes.icon} />
            </div>
            {!!fileName && (
              <div className={classes.loadedFile}>
                <Text variant="caption1">
                  <FontAwesomeIcon>check</FontAwesomeIcon> {fileName}
                </Text>
              </div>
            )}
            <div className={classes.dropzoneText}>
              <Text variant="body2">
                <SimpleLink onClick={open}>Click to upload</SimpleLink> <b>or drag and drop</b>
              </Text>
            </div>
          </div>
        </div>
        {hasError && <Warning>{errorMessage}</Warning>}
      </div>
    )
  },
)

export default withFormContext(FileDrop) as React.FC<Props>
