import React, { useMemo } from 'react'
import UsageWidget from 'core/components/widgets/UsageWidget'
import { getHostUsageTotals } from './helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  hostCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 12,
    marginBottom: 12,
  },
}))

export default function HostsListPageHeader({ hosts }) {
  const classes = useStyles()
  const totals = getHostUsageTotals(hosts)

  return (
    <div className={classes.hostCards}>
      <UsageWidget
        icon="microchip"
        title="CPU"
        stats={totals.compute}
        units="GHz"
        primary={`out of ${totals.compute?.max?.toFixed(1)} GHz`}
      />
      <UsageWidget
        icon="memory"
        title="Memory"
        stats={totals.memory}
        units="GiB"
        primary={`out of ${totals.memory?.max?.toFixed(1)} GiB`}
      />
      <UsageWidget
        icon="sim-card"
        title="Storage"
        stats={totals.disk}
        units="GiB"
        primary={`out of ${totals.disk?.max?.toFixed(1)} GiB`}
      />
    </div>
  )
}
