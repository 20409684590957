import {
  listMngmGroupMappings,
  listMngmGroups,
} from 'account/components/ssoManagement/groups/new-actions'
import { listMngmRoles } from 'account/components/userManagement/roles/new-actions'
import {
  listTenantRoleAssignments,
  listTenants,
  listUserTenants,
} from 'account/components/userManagement/tenants/new-actions'
import {
  listCredentials,
  listUserRoleAssignments,
  listUsers,
} from 'account/components/userManagement/users/new-actions'
import {
  listCloudProviders,
  loadAwsRegionInfo,
} from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { listCapiAwsClusters } from 'app/plugins/infrastructure/components/clusters/aws/capi/actions'
import {
  listAwsAmis,
  listCapiClusters,
} from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { listAwsManagedControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/aws-managed-control-plane/actions'
import { listNodeletControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/nodelet-control-plane/actions'
import {
  listAddonVersions,
  listClusterAddons,
  listClusterVersions,
  listEksAddons,
  listKubevirtAddons,
  listMonitoringAddons,
  listNetworkingAddons,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import {
  listClusterEvents,
  listClusters,
  listSupportedRoleVersions,
} from 'app/plugins/infrastructure/components/clusters/newActions'
import {
  listClusterAgents,
  listImportedClusters,
} from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listK8sNodes, listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { listResMgrHosts } from 'app/plugins/infrastructure/components/resMgrHosts/actions'
import {
  listIpAllocations,
  listLiveMigrations,
  listVirtualMachineInstances,
  listVirtualMachinePresets,
  listVirtualMachines,
} from 'app/plugins/kubevirt/components/virtual-machines/new-actions'
import ListAction from 'core/actions/ListAction'
import { listAlerts, listSilences, listTimeSeriesAlerts } from 'k8s/components/alarms/new-actions'
import { listApps, listAppsAvailableToCluster } from 'k8s/components/app-catalog/apps/new-actions'
import { listDeployedApps } from 'k8s/components/app-catalog/deployed-apps/new-actions'
import {
  listRepositories,
  listRepositoriesForCluster,
} from 'k8s/components/app-catalog/repositories/new-actions'
import { listConfigMaps } from 'k8s/components/config-maps/new-actions'
import { listSecrets } from 'k8s/components/configuration/secrets/actions'
import { listCronjobs, listJobs } from 'k8s/components/cronjobs/actions'
import { listDaemonSets } from 'k8s/components/daemon-sets/actions'
import { listDeployments } from 'k8s/components/deployments/new-actions'
import { listIngresses } from 'k8s/components/ingresses/new-actions'
import { listAlertRules } from 'k8s/components/monitoring/new-actions'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { listHostNetworkTemplates } from 'k8s/components/networking/host-configs/actions'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { listNetworkPlugins } from 'k8s/components/networking/plugins/actions'
import { listPodMetrics } from 'k8s/components/pods/metrics/new-actions'
import { listPods } from 'k8s/components/pods/new-actions'
import { listPrometheusRules, listServiceAccounts } from 'k8s/components/prometheus/new-actions'
import {
  listApiGroups,
  listApiResources,
  listClusterRoleBindings,
  listClusterRoles,
  listCoreApiResources,
  listRoleBindings,
  listRoles,
} from 'k8s/components/rbac/new-actions'
import { listRbacProfileBindings, listRbacProfiles } from 'k8s/components/rbac/profiles/new-actions'
import { listReplicaSets } from 'k8s/components/replica-sets/actions'
import { listServices } from 'k8s/components/services/new-actions'
import { listStatefulSets } from 'k8s/components/stateful-sets/actions'
import { listCSIDrivers } from 'k8s/components/storage/csi-drivers/new-actions'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { listPersistentVolumes } from 'k8s/components/storage/persistent-volume/new-actions'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'
import { IDataKeys } from 'k8s/datakeys.model'
import { listFlavors } from 'openstack/components/flavors/actions'
import { listImages } from 'openstack/components/images/actions'
import {
  listHostAggregates,
  listHostNetworkConfigs,
  listHypervisors,
  listResourceInventory,
} from 'openstack/components/infrastructure/actions'
import { listAvailabilityZones } from 'openstack/components/infrastructure/availability-zones/actions'
import {
  listNetworkAvailability,
  listNetworks as listOpenstackNetworks,
  listPorts as listOpenstackPorts,
  listSubnets as listOpenstackSubnets,
} from 'openstack/components/networks/actions'
import { listFloatingIps } from 'openstack/components/networks/floating-ips/actions'
import { listRouters as listOpenstackRouters } from 'openstack/components/networks/routers/actions'
import { listSecurityGroups } from 'openstack/components/networks/security-groups/actions'
import { listStacks } from 'openstack/components/orchestration/actions'
import { listSshKeys } from 'openstack/components/security/keys/actions'
import { listVolumeSnapshots as listOpenstackVolumeSnapshots } from 'openstack/components/storage/snapshots/actions'
import {
  listAllVolumes,
  listVolumes as listOpenstackVolumes,
} from 'openstack/components/storage/volumes/actions'
import {
  listAllVirtualMachines as listAllOpenstackVirtualMachines,
  listVirtualMachines as listOpenstackVirtualMachines,
} from 'openstack/components/vms/actions'
import { listServerGroups } from 'openstack/components/vms/server-groups/actions'
import { listAwsMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-machine-pool/actions'
import { listAwsMachineTemplates } from './plugins/infrastructure/components/clusters/capi/aws-machine-templates/actions'
import { listAwsManagedMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-managed-machine-pool/actions'
import {
  listEksConfigs,
  listEksConfigTemplates,
  listNodeletConfigs,
  listNodeletConfigTemplates,
} from './plugins/infrastructure/components/clusters/capi/configs/actions'
import { listCapiClusterEvents } from './plugins/infrastructure/components/clusters/capi/details/overview/events/actions'
import { listCapiHosts } from './plugins/infrastructure/components/clusters/capi/details/overview/hosts/actions'
import { listEksAmiTypes } from './plugins/infrastructure/components/clusters/capi/eks-ami-types/actions'
import {
  listAwsClusterRoleIdentities,
  listAwsClusterStaticIdentities,
} from './plugins/infrastructure/components/clusters/capi/identities/actions'
import { listMachineDeployments } from './plugins/infrastructure/components/clusters/capi/machine-deployment/actions'
import { listMachinePools } from './plugins/infrastructure/components/clusters/capi/machine-pool/actions'
import { listClusterUpgradeJobs } from './plugins/infrastructure/components/clusters/capi/upgrade/actions'
import { listEksVersions } from './plugins/infrastructure/components/versions/eks/actions'
import {
  listClusterInstanceTypes,
  listInstanceTypes,
} from './plugins/kubevirt/components/virtual-machines/instance-types/actions'
import { listStoragePools } from './plugins/openstack/components/storage/pools/actions'
import { listVolumeTypes } from './plugins/openstack/components/storage/volume-types/actions'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { listKaapiOpenStackClusters } from 'k8s/components/kaapi/openstack-clusters/actions'
import { listKaapiHostedControlPlanes } from 'k8s/components/kaapi/hosted-control-plane/actions'
import { listkaapiOpenstackMachineTemplates } from 'k8s/components/kaapi/openstack-machine-templates/actions'
import { listKaapiMachineDeployments } from 'k8s/components/kaapi/machine-deployment/actions'
import { listkaapiKubeadmConfigTemplates } from 'k8s/components/kaapi/kubeadm-config-templates/actions'
import { listKaapiMachines } from 'k8s/components/kaapi/machines/actions'
import { listKaapiClusterUpgradeJobs } from 'k8s/components/kaapi/cluster-upgrade/actions'
import { listKaapiNodes } from 'k8s/components/kaapi/workload-clusters/nodes/actions'
import { listKaapiConfigMaps } from 'k8s/components/kaapi/config-maps/actions'
import { listKaapiSecrets } from 'k8s/components/kaapi/secrets/actions'

const listActionsByKey: Record<keyof IDataKeys, ListAction<keyof IDataKeys>> = {
  AddonVersions: listAddonVersions,
  AlertRules: listAlertRules,
  Alerts: listAlerts,
  AlertsTimeSeries: listTimeSeriesAlerts,
  AllClusters: listClusters,
  ApiEndpoints: null, // @todo
  ApiGroups: listApiGroups,
  ApiResources: listApiResources,
  AppDetails: null, // @todo
  Apps: listApps,
  AppsAvailableToCluster: listAppsAvailableToCluster,
  AppVersions: null,
  CloudProviderDetails: null, // @todo
  CloudProviderRegionDetails: null, // @todo
  CloudProviders: listCloudProviders,
  ClusterAddons: listClusterAddons,
  ClusterRoleBindings: listClusterRoleBindings,
  ClusterRoles: listClusterRoles,
  Endpoints: null,
  PrometheusInstances: null, // @todo
  ServiceAccounts: listServiceAccounts,
  PrometheusRules: listPrometheusRules,
  PrometheusServiceMonitors: null, // @todo
  PrometheusAlertManagers: null, // @todo
  StorageClasses: listStorageClasses,
  CSIDrivers: listCSIDrivers,
  PersistentVolumes: listPersistentVolumes,
  PersistentVolumeClaims: listPersistentVolumeClaims,
  Silences: listSilences,
  Ssh: null, // @todo
  ServiceCatalog: null, // @todo
  Clusters: listClusters,
  CoreApiResources: listCoreApiResources,
  DeployedAppDetails: null, // @todo
  DeployedApps: listDeployedApps,
  Deployments: listDeployments,
  Flavors: listFlavors, // @todo
  ImportedClusters: listImportedClusters,
  Ingresses: listIngresses,
  KubeRoles: listRoles,
  KubeServiceDetails: null, // @todo
  KubeServices: listServices,
  Services: listServices,
  ManagementCredentials: listCredentials,
  ManagementGroups: listMngmGroups,
  ManagementGroupsMappings: listMngmGroupMappings,
  ManagementRoles: listMngmRoles,
  ManagementTenants: listTenants,
  ManagementTenantsRoleAssignments: listTenantRoleAssignments,
  ManagementUsers: listUsers,
  ManagementUsersRoleAssignments: listUserRoleAssignments,
  Namespaces: listNamespaces,
  Nodes: listNodes,
  K8sNodes: listK8sNodes,
  PodMetrics: listPodMetrics,
  PodTemplates: null,
  Pods: listPods,
  RbacProfileBindings: listRbacProfileBindings,
  RbacProfiles: listRbacProfiles,
  Regions: listRegions,
  Repositories: listRepositories,
  RepositoriesForCluster: listRepositoriesForCluster,
  ResMgrHosts: listResMgrHosts,
  RoleBindings: listRoleBindings,
  SupportedRoleVersions: listSupportedRoleVersions,
  EksVersions: listEksVersions,
  AwsRegionInfo: loadAwsRegionInfo,
  Tenants: listTenants,
  UserTenants: listUserTenants,
  ConfigMaps: listConfigMaps,
  Users: listUsers,
  VirtualMachineDetails: null, // @todo
  VirtualMachines: listVirtualMachines,
  VirtualMachineInstances: listVirtualMachineInstances,
  VirtualMachinePresets: listVirtualMachinePresets,
  Networks: listNetworks,
  DataVolumes: listDataVolumes,
  Events: listClusterEvents,
  CapiEvents: listCapiClusterEvents,
  Cronjobs: listCronjobs,
  Jobs: listJobs,
  StatefulSets: listStatefulSets,
  ReplicaSets: listReplicaSets,
  DaemonSets: listDaemonSets,
  MonitoringAddons: listMonitoringAddons,
  KubevirtAddons: listKubevirtAddons,
  NetworkingAddons: listNetworkingAddons,
  ClusterAgents: listClusterAgents,
  CapiClusters: listCapiClusters,
  CapiAwsClusters: listCapiAwsClusters,
  NodeletControlPlanes: listNodeletControlPlanes,
  AwsAmis: listAwsAmis,
  AwsManagedControlPlanes: listAwsManagedControlPlanes,
  CapiHosts: listCapiHosts,
  AwsMachineTemplates: listAwsMachineTemplates,
  MachineDeployments: listMachineDeployments,
  MachinePools: listMachinePools,
  AwsMachinePools: listAwsMachinePools,
  AwsManagedMachinePools: listAwsManagedMachinePools,
  EksConfigs: listEksConfigs,
  EksConfigTemplates: listEksConfigTemplates,
  NodeletConfigs: listNodeletConfigs,
  NodeletConfigTemplates: listNodeletConfigTemplates,
  AwsClusterRoleIdentities: listAwsClusterRoleIdentities,
  AwsClusterStaticIdentities: listAwsClusterStaticIdentities,
  ClusterVersions: listClusterVersions,
  LiveMigrations: listLiveMigrations,
  ClusterUpgradeJobs: listClusterUpgradeJobs,
  EksAmiTypes: listEksAmiTypes,
  InstanceTypes: listInstanceTypes,
  ClusterInstanceTypes: listClusterInstanceTypes,
  EksAddons: listEksAddons,
  IpAllocations: listIpAllocations,
  NetworkPlugins: listNetworkPlugins,
  HostNetworkTemplates: listHostNetworkTemplates,
  Secrets: listSecrets,
  Images: listImages,
  OpenstackVirtualMachines: listOpenstackVirtualMachines,
  AllOpenstackVirtualMachines: listAllOpenstackVirtualMachines,
  OpenstackNetworks: listOpenstackNetworks,
  OpenstackSubnets: listOpenstackSubnets,
  OpenstackPorts: listOpenstackPorts,
  OpenstackRouters: listOpenstackRouters,
  OpenstackVolumes: listOpenstackVolumes,
  AllOpenstackVolumes: listAllVolumes,
  OpenstackVolumeSnapshots: listOpenstackVolumeSnapshots,
  NetworkIpAvailability: listNetworkAvailability,
  ResmgrHosts: listResMgrHosts,
  Hypervisors: listHypervisors,
  SshKeys: listSshKeys,
  FloatingIps: listFloatingIps,
  SecurityGroups: listSecurityGroups,
  VolumeTypes: listVolumeTypes,
  HostAggregates: listHostAggregates,
  Stacks: listStacks,
  AvailabilityZones: listAvailabilityZones,
  ServerGroups: listServerGroups,
  StoragePools: listStoragePools,
  HostNetworkConfigs: listHostNetworkConfigs,

  // Todo: create list actions for these
  KaapiClusters: listKaapiClusters,
  KaapiOpenStackClusters: listKaapiOpenStackClusters,
  KaapiHostedControlPlanes: listKaapiHostedControlPlanes,
  KaapiOpenStackMachineTemplates: listkaapiOpenstackMachineTemplates,
  KubeadmConfigTemplates: listkaapiKubeadmConfigTemplates,
  KaapiMachineDeployments: listKaapiMachineDeployments,
  KaapiMachines: listKaapiMachines,
  KaapiClusterAddons: null,
  KaapiTenants: null,
  KaapiClusterUpgradeJobs: listKaapiClusterUpgradeJobs,
  KaapiK8sClusterUpgrades: null,
  KaapiConfigMaps: listKaapiConfigMaps,
  KaapiNodes: listKaapiNodes,
  KaapiSecrets: listKaapiSecrets,
}

export default listActionsByKey
